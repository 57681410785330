import styled from "styled-components";
import { Figure, Toast } from "react-bootstrap";

export const StyledHeading = styled.div`
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

export const BibTexStyled = styled.pre`
  display: block;
  font-family: Courier New, monospace;
  font-size: 12px;
  line-height: 22px;
  white-space: pre-wrap;
  margin: 1em 0px;
`;

export const FigureImage = styled(Figure.Image)`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

export const StyledToast = styled(Toast)`
  position: absolute;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
`;

export const StyledYear = styled.h5`
  color: #e11a2c;
  font-size: 25px;
  padding-left: 4px;
`;
