import styled from "styled-components";
import { Card, Col } from "react-bootstrap";

export const StyledCard = styled(Card)`
  width: 10rem;
  height: 100%;
  color: #000000;
  transition: transform 0.2s;
  font-family: "HelveticaNeueW01-65Medi", "HelveticaNeueW01-45Ligh",
    "Helvetica Neue", HelveticaNeue, Helvetica, sans-serif;
  font-weight: normal;
  text-transform: none;
  border: none;
  text-align: center;
  color: #000000;
  -moz-box-shadow: 0 0 10px #808080;
  -webkit-box-shadow: 0 0 10px #808080;
  box-shadow: 0 0 10px #808080;
  &:hover {
    // text-decoration: none;
    transform: translateY(0) scale(1.03);
  }
`;
export const StyledA = styled.a`
  text-decoration: none;
  text-color: #b3cde3;
  padding: 0px;
  margin: 0px;
`;

export const StyledCol = styled(Col)`
  padding: 6px;
`;
export const StyledCardBody = styled(Card.Body)`
  padding: 4px;
  // text-decoration: none;
`;
