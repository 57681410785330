import React, { useState, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import Home from "./Home";
import Projects from "./Projects";
import Publications from "./Publications";
import People from "./People";
import Teaching from "./Teaching";
import Layout from "../components/Layout";
import Recruitment from "./Recruitment";
import Archive from "./Archive";

import { Helmet } from "react-helmet";

const Pages = ({ home, papers, people, projects, teaching }) => {
  let individuals = {};
  const [BibTexFile, setBibTexFile] = useState({});
  useEffect(() => {
    fetch(process.env.PUBLIC_URL + "/bibtex/bibtex.bib")
      .then((r) => {
        if (!r.ok) {
          throw Error(r.statusText);
        }
        return r.text();
      })
      .then((text) => {
        //https://regex101.com/r/b0KMlF/1
        let regex = /@([\s\S]*?)^}/gm;
        let bibtexs = text.match(regex);
        let names = text.match(/@\S+{([\s\S]*?),/gm);
        let name2bib = {};
        for (let i = 0; i < bibtexs.length; i++) {
          let name = names[i].split("{")[1].replace(",", "").trim();
          name2bib[name] = bibtexs[i];
        }
        setBibTexFile(name2bib);
      });
  }, []);
  for (const [, persons] of Object.entries(people)) {
    for (const individual of persons) {
      individuals[individual.name] = individual;
      if (individual.alias) {
        for (const name of individual.alias) {
          individuals[name] = individual;
        }
      }
    }
  }
  return (
    <Layout>
      <Switch>
        <Route exact path="/">
          <Helmet>
            <title>Khoury Data Visualization, Northeastern University</title>
            <meta
              name="description"
              content="Front page Khoury Data Visualization Group, Northeastern University"
            />
          </Helmet>
          <Home data={home} papers={papers} people={individuals} />
        </Route>
        <Route path="/publications">
          <Helmet>
            <title>Publications - Khoury Data Visualization, Northeastern University</title>
            <meta
              name="description"
              content="Publications of the Khoury Data Visualization Group, Northeastern University"
            />
          </Helmet>
          <Publications
            papers={papers}
            people={individuals}
            BibTexFile={BibTexFile}
          />
        </Route>
        <Route path="/people">
          <Helmet>
            <title>People - Khoury Data Visualization, Northeastern University</title>
            <meta
              name="description"
              content="People of the Khoury Data Visualization Group, Northeastern University"
            />
          </Helmet>
          <People
            people={people}
            peopleDict={individuals}
            papers={papers.publications}
          />
        </Route>
        <Route path="/open-positions">
          <Helmet>
            <title>Open Positions - Khoury Data Visualization, Northeastern University</title>
            <meta
              name="description"
              content="Open Positions of the Khoury Data Visualization Group, Northeastern University"
            />
          </Helmet>
          <Recruitment />
        </Route>
        <Route path="/teaching">
          <Helmet>
            <title>Teaching - Khoury Data Visualization, Northeastern University</title>
            <meta
              name="description"
              content="Courses taught by members of the Khoury Data Visualization Group, Northeastern University"
            />
          </Helmet>
          <Teaching teaching={teaching} people={individuals} />
        </Route>
        <Route path="/projects">
          <Helmet>
            <title>Projects - Khoury Data Visualization, Northeastern University</title>
            <meta
              name="description"
              content="Projects of the Khoury Data Visualization Group, Northeastern University"
            />
          </Helmet>
          <Projects
            projects={projects.projects}
            people={individuals}
            papers={papers.publications}
          />
        </Route>
        <Route path="/archive">
          <Helmet>
            <title>Archive Updates - Khoury Data Visualization, Northeastern University</title>
            <meta
              name="description"
              content="Archive Updates of the Khoury Data Visualization Group, Northeastern University"
            />
          </Helmet>
          <Archive data={home} />
        </Route>
        <Route path="*">
          <Helmet>
            <title>Khoury Data Visualization, Northeastern University</title>
            <meta
              name="description"
              content="Front page of the Khoury Data Visualization Group, Northeastern University"
            />
          </Helmet>
          <Home data={home} papers={papers} people={individuals} />
        </Route>
      </Switch>
    </Layout>
  );
};

export default Pages;
