import React from "react";
import {} from "./styles";
import { StyledTitle, StyledSubTitle } from "../../styles";

import "./style.css";

const Recruitment = () => {
  return (
    <div class="row">
      <article class="post page">
        <header class="article-header">
          <StyledTitle>Open Positions</StyledTitle>
        </header>
        <div class="article-content">
          <p>
            We have a growing focus on data visualization at Northeastern. The{" "}
            <a href="http://www.khoury.northeastern.edu/">
              Khoury College of Computer Sciences
            </a>{" "}
            has several professors focused on{" "}
            <a href="https://visualization.khoury.northeastern.edu">
              visualization research
            </a>
            .
            They also support two new degree programs in{" "}
            <a href="http://www.khoury.northeastern.edu/research-area/data-science/">
              Data Science
            </a>
            . The college includes a strong team of 62 tenured and tenure-track faculty and another 55 teaching faculty, and the College plans to continue hiring. Northeastern is also now top ranked among top computer science schools in terms of publications at selective venues.
          </p>
          <p>
            Beyond Khoury College, visualization work is being conducted across campus by faculty in Art and Design, Journalism, Politics, Engineering, and the Library. The interdisciplinary{" "}
            <a href="http://nuvis.northeastern.edu/">
              NUVis Visualization Consortium
            </a>{" "}
            ties these researchers together, and helps foster cross-cutting studies. We have a rich tradition of working on meaningful problems in collaboration with civic, business, and global partners.
          </p>
          <p>
            Our warm supportive community of researchers eagerly seeks women and minority students, postdocs, research scientists, and faculty to promote diversity.
          </p>
          
          <StyledTitle>Faculty</StyledTitle>
          <p>
            (1) The College of Arts, Media, and Design is searching for an Assistant Professor of Information Design and Data Visualization to be hired jointly with Khoury College and/or the <a href="https://www.networkscienceinstitute.org/">Network Science Institute</a>. For more information, please see <a href="https://careers.hrm.northeastern.edu/en-us/job/508570/assistant-professor-information-design-and-data-visualization">the job announcement and application instructions</a> or reach out to <a class="at" href="/people/Enrico-Bertini">Enrico Bertini</a> and <a class="at" href="/people/Melanie-Tory">Melanie Tory</a>.
          </p>
          <p>
            (2) We are searching for a Research Professor focused on visualization and visual analytics to join the <a href="https://roux.northeastern.edu/">Roux Institute</a> of Northeastern University in Portland, ME. Consider joining our growing interdisciplinary team! See the{" "}<a href="https://roux.northeastern.edu/research-opportunities/#datavisualization">Roux hiring site</a> and the{" "}
            <a href="https://careers.hrm.northeastern.edu/en-us/job/506730/research-professor-and-roux-institute-member-open-rank-visualization-and-visual-analytics">
              full job announcement and application instructions
            </a>{" "} for details, and please feel free to reach out to <a class="at" href="/people/Melanie-Tory">Melanie Tory</a> with any questions you may have.
          </p>
          <p>
            (3) The College is searching more broadly for professors, teaching professors, and lecturers across all areas. See the{" "}
            <a href="https://www.khoury.northeastern.edu/information-for-overview/prospective-faculty/open-positions/">
              College's open positions page
            </a>{" "}
            for more details.
          </p>

          <StyledTitle>Research Scientist</StyledTitle>
          <p>
            We are searching for a Research Scientist focused on data visualization and visual analytics to join the <a href="https://roux.northeastern.edu/">Roux Institute</a> of
            Northeastern University in Portland, ME. See the{" "}<a href="https://roux.northeastern.edu/research-opportunities/#datavisualization">Roux hiring site</a> and the{" "}
            <a href="https://careers.hrm.northeastern.edu/en-us/job/507158/research-scientist-data-visualization">
              full job announcement and application instructions
            </a>
            . Point of contact: <a class="at" href="/people/Melanie-Tory">Melanie Tory</a>.
          </p>

          <StyledTitle>Postdoctoral Researcher</StyledTitle>
          <p>
            We have an open position for a postdoc working with <a class="at" href="/people/Melanie-Tory">Melanie Tory</a> on data visualization and visual analytics for Engineering and Biomedicine applications. Please see the{" "}<a href="https://careersmanager.pageuppeople.com/879/cw/en-us/job/507645/visualization-postdoctoral-fellow">announcement and application instructions</a> for more and reach out to Melanie with any questions.
          </p>

          <StyledTitle>Student Research</StyledTitle>
          <StyledSubTitle>
            PhD Research
            <strong>
              <br />
            </strong>
          </StyledSubTitle>
          <p>
            At Northeastern, students interested in graduate studies on visualization have{" "}<strong>several degree programs to choose from</strong>. We offer PhD programs in 
            {" "}<a href="https://www.khoury.northeastern.edu/program/computer-science-phd/">Computer Science</a>,
            {" "}<a href="https://phi.khoury.northeastern.edu/">Personal Health Informatics</a>,
            {" "}<a href="http://www.networkscienceinstitute.org/phd">Network Science</a>, and
            {" "}<a href="http://www.networkscienceinstitute.org/phd">Cybersecurity</a>.
            We will help guide incoming students in applying to the appropriate degree program for their interests.
          </p>
          <p>
            <a class="at" href="/people/Cody-Dunne">Prof. Cody Dunne</a> currently has <strong>two open and funded PhD positions</strong>, possibly focused on layered graph  drawing / network visualization, reverse engineering software, cybersecurity and defense geospatial network visualization, SQL query visualization, temporal event sequence visualization esp. for healthcare, multidimensional data visualization, visualization perception and cognition, immersive analytics / virtual reality, and visualizing sensor fusion algorithms.
          </p>
          <p>
          <a class="at" href="/people/Enrico-Bertini">Prof. Bertini</a> is looking for students with a broad interest in data visualization and visual analytics. Areas of interest include but are not limited to visualization for machine learning, visual perception and cognition, data visualization theory and methodology, visualization beyond the desktop, data sensemaking, and education in visualization.
          </p>
          <p>
            PhD students in these programs who are making acceptable progress are guaranteed at least five years of full funding.{" "}<strong>Spring PhD admission is available for exceptional students.</strong>
          </p>
          <StyledSubTitle>Undergraduate &amp; Master's Research</StyledSubTitle>
          <p>
            We are also looking for exceptional undergraduate and master's students to work as part of dynamic teams with our graduate students and faculty. Research with us can be paid or unpaid for research experience. We hire students hourly or for co-ops.
          </p>
          <StyledSubTitle>
            How to Apply
            <strong>
              <br />
            </strong>
          </StyledSubTitle>
          <p>
            We are particularly interested in candidates with strong programming proficiency, such as full stack web development experience, JavaScript, D3, WebGL, Python, and Java. Experience with user study design, perceptual psychology, and vision science is also valuable. Candidates should have strong English speaking and writing skills.
          </p>
          <p>
            If you are interested in applying to work with us, please email us at{" "}
            <a href="mailto:visualization-apply@ccs.neu.edu">
              visualization-apply@ccs.neu.edu
            </a>{" "}
            including:
          </p>
          <ul>
            <li>
              A statement of your research interests, which of us you want to work with, and what work of ours is interesting to you;
            </li>
            <li>Your CV;</li>
            <li>Academic transcripts (can be unofficial);</li>
            <li>
              Test scores for students: GRE for graduate students, SAT for undergraduate, and TOEFL/IELTS if foreign; and
            </li>
            <li>
              Any additional materials that would help us evaluate your skills, such as your homepage, design portfolio, online demos or videos, and GitHub profile.
            </li>
          </ul>
          <p>
            If we think you would be suitable research partners, we will
            schedule an interview with you.
          </p>
          <p>
            The official PhD program application form is available{" "}
            <a href="https://www.khoury.northeastern.edu/apply/phd-apply/">
              here
            </a>
            .
          </p>
          {/* <StyledSubTitle>Career Counselors and Faculty</StyledSubTitle>
          <p>
            Please consider posting{" "}
            <a href="https://visualization.khoury.northeastern.edu/files/2020/10/Data_Visualization_at_Northeastern_Khoury.pdf">
              our student recruiting flier
            </a>{" "}
            (from 2020).
          </p>
          <p>
            <a href="https://visualization.khoury.northeastern.edu/files/2020/10/Data_Visualization_at_Northeastern_Khoury.pdf">
              <img
                loading="lazy"
                class="alignnone size-medium wp-image-106"
                src="https://visualization.khoury.northeastern.edu/files/2020/10/Data_Visualization_at_Northeastern_Khoury-791x1024.png"
                alt="Image of flier about data visualization at Northeastern Khoury"
                width="232"
                height="300"
              />
            </a>
          </p> */}
        </div>
      </article>
    </div>
  );
};

export default Recruitment;
