import styled from "styled-components";

export const Paragraph = styled.p`
  white-space: pre-wrap;
`;

export const Pill = styled.span`
  display: inline-block;
  margin-right: 0.75rem;
  margin-bottom: 0.75rem;
  padding: 0.5rem 1rem;
  border-radius: 2px;
  font-weight: bold;
`;

export const StyledTitle = styled.h3`
  font-family: "HelveticaNeueW01-67MdCn 692710", "HelveticaNeueW01-45Ligh",
    "Helvetica Neue", HelveticaNeue, Helvetica, sans-serif;
  font-weight: normal;
  line-height: 1.1;
  color: #615445;
  font-size: 30px;
  text-transform: uppercase;
  margin-bottom: 10px;
`;

export const StyledSubTitle = styled.h4`
  font-family: "HelveticaNeueW01-67MdCn 692710", "HelveticaNeueW01-45Ligh",
    "Helvetica Neue", HelveticaNeue, Helvetica, sans-serif;
  font-weight: normal;
  line-height: 1.1;
  color: #615445;
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 10px;
`;
