import React from "react";
import {
  CarouselImg,
  StyledCarousel,
  CenteredRow,
  UpdateDate,
  UpdateText,
  CarouselText,
} from "./styles";
import { StyledTitle } from "../../styles";
import { Carousel, Row, Col } from "react-bootstrap";
import Citation from "../../components/Citation";
import "./style.css";

const Carousels = ({ carousels }) => {
  return (
    <StyledCarousel interval={null} indicators={true} controls={true}>
      {carousels.map((carousel, index) => (
        <Carousel.Item key={index}>
          <a href={process.env.PUBLIC_URL + carousel.link}>
            <CarouselImg
              style={{ height: "400px" }}
              src={process.env.PUBLIC_URL + carousel.image}
              alt={"Slide " + index + ": " + carousel.title}
            />
          </a>
          <Carousel.Caption>
            <CarouselText>{carousel.title}</CarouselText>
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </StyledCarousel>
  );
};

const About = () => {
  return (
    <div className="article-content">
      <StyledTitle>Home</StyledTitle>
      <p>
        Data visualizations are designed to leverage the impressive human visual
        bandwidth so that users can spot clusters, gaps, trends, outliers in the
        data within a fraction of a second. Visualizations, combined with
        interaction and animation techniques, can help experts interpret and
        explore complex data as well as gain confidence in their algorithmic
        results. Moreover, visualizations are highly effective tools for
        communicating with other analysts or stakeholders. Research on
        visualization involves understanding human perception and vision, visual
        encodings, design thinking, color choice, data simplification,
        interaction techniques, and animation techniques and designing the next
        generation of data analysis and communication tools. We have several
        current <a href="/open-positions">research opportunities</a> available
        for faculty, research scientists, postdoctoral researchers, and students
        joining our PhD, master's, and bachelor's programs.
      </p>
      <p>
        <strong>Areas of investigation:</strong>
      </p>
      <div style={{ width: "100%" }}>
        <div style={{ width: "50%", float: "left" }}>
          <ul>
            <li>Perception and cognition</li>
            <li>Data storytelling</li>
            <li>Analytic provenance</li>
            <li>Exploratory data analysis</li>
            <li>Coordinated views and interactions</li>
            <li>Tree/hierarchical data</li>
            <li>Network data</li>
          </ul>
        </div>
        <div style={{ width: "50%", float: "right" }}>
          <ul>
            <li>Multidimensional data</li>
            <li>Geospatial data</li>
            <li>Uncertain, missing, erroneous data</li>
            <li>Temporal event sequences</li>
            <li>User interface design</li>
            <li>Interaction design</li>
            <li>Evaluation methodologies</li>
          </ul>
        </div>
      </div>
      <p>
        <strong>Specific domains of interest:</strong>
      </p>
      <ul>
        <li>
          <strong>Healthcare diagnostic</strong>{" "}
          <strong>and treatment decision support</strong>, including diabetes,
          neurology, ophthalmology
        </li>
        <li>
          <strong>Cybersecurity</strong>, including unmanned autonomous system
          analysis
        </li>
        <li>
          <strong>Astronomy and physics</strong>, including 3D visualization and
          multidimensional data
        </li>
        <li>
          <strong>Computer Science</strong>, including work in databases,
          programming languages, and systems
        </li>
        <li>
          <strong>Digital humanities</strong>, including networks of concepts in
          humanities texts, text and timeline visualizations
        </li>
        <li>
          <strong>Epidemiology</strong>, including the spread of infectious
          disease
        </li>
      </ul>
    </div>
  );
};

const Updates = ({ updates }) => {
  let recUpdates = updates.slice(0, 5);
  return (
    <>
      <StyledTitle>Updates</StyledTitle>
      {recUpdates.map((update, index) => (
        <Row key={index}>
          <div>
            <UpdateDate>{update.date}</UpdateDate>:{" "}
            <UpdateText dangerouslySetInnerHTML={{ __html: update.html }} />
          </div>
        </Row>
      ))}
    </>
  );
};

const RecentPublications = ({ papers, people, count }) => {
  let recPub = papers.slice(0, count);
  return (
    <>
      <StyledTitle>Recent Publications</StyledTitle>
      {recPub.map((pub, index) => (
        <Citation key={index} pub={pub} people={people} />
      ))}
    </>
  );
};

const Home = ({ data, papers, people }) => {
  return (
    <>
      <CenteredRow>
        <Carousels carousels={data.carousels} />
      </CenteredRow>
      <Row>
        <Col sm={9}>
          <About />
        </Col>
        <Col sm={3}>
          <Updates updates={data.updates} />
          <Row>
            <a href={process.env.PUBLIC_URL + "/archive"} style={{ paddingTop: "20px" }}>
              All Archive
            </a>
          </Row>
        </Col>
      </Row>
      <hr />
      <RecentPublications
        papers={papers.publications}
        people={people}
        count={8}
      />
    </>
  );
};

export default Home;
