import React from "react";
import { Card } from "react-bootstrap";
import { StyledCard, StyledCol, StyledCardBody, StyledA } from "./style";
import Identicon from "react-identicons";
const PersonCard = ({ name, people }) => {
  if (name in people) {
    let person = people[name];
    let pageLink = process.env.PUBLIC_URL + "/people/" + person.name.trim().replaceAll(" ", "-");
    return (
      <StyledA href={pageLink}>
        <StyledCard>
          {person.image ? (
            <Card.Img
              variant="top"
              src={process.env.PUBLIC_URL + person.image}
            />
          ) : (
            <Identicon string={person.name} size={160} />
          )}
          <StyledCardBody>{person.name}</StyledCardBody>
        </StyledCard>
      </StyledA>
    );
  } else {
    return (
      <StyledCard>
        <Identicon string={name} size={160} />
        <StyledCardBody>{name}</StyledCardBody>
      </StyledCard>
    );
  }
};

const AuthorImg = ({ names, people }) => {
  return names.map((name, index) => (
    <StyledCol>
      <PersonCard key={index} name={name} people={people} />
    </StyledCol>
  ));
};

export default AuthorImg;
