import React, { useState } from "react";
import { Switch, useParams, Route, useRouteMatch } from "react-router-dom";

import { StyledTitle } from "../../styles";
import { Figure, Button, Toast, Row } from "react-bootstrap";
import {
  StyledHeading,
  FigureImage,
  BibTexStyled,
  StyledToast,
  StyledYear,
} from "./styles";
import Citation from "../../components/Citation";
import Materials from "../../components/Materials";
import AuthorImg from "../../components/AuthorImg";
import { ImCopy } from "react-icons/im";
import { MdCheckCircle } from "react-icons/md";
import { Helmet } from "react-helmet";

const Paper = ({ papers, people }) => {
  let { paperId } = useParams();
  let paper = papers.find((p) => p.urlId === paperId);
  return (
    <div>
      <h3>{paper.title}</h3>
      <Helmet>
        <title>{paper.title} - Khoury Data Visualization, Northeastern University</title>
        <meta name="description" content={paper.title + " - Khoury Data Visualization, Northeastern University"} />
      </Helmet>
      <Figure>
        <FigureImage
          src={process.env.PUBLIC_URL + paper.image}
          alt={paper.altText}
        />
        <Figure.Caption>{paper.imageDesc}</Figure.Caption>
      </Figure>
      <StyledHeading>Abstract</StyledHeading>
      <p>
        <div dangerouslySetInnerHTML={{ __html: paper.abstract }} />
      </p>
      <StyledHeading>Materials</StyledHeading>
      <Materials
        materials={paper.materials}
        award={paper.award}
        bibTex={[paper.bibTex, paper.urlId]}
      />
      <br />
      <br />
      <StyledHeading>Authors</StyledHeading>
      <h5>
        <Row xs="auto" md="auto" lg="auto" style={{ paddingLeft: "12px" }}>
          <AuthorImg names={paper.authors} people={people} />
        </Row>
      </h5>
      <StyledHeading>Citation</StyledHeading>
      <Citation pub={paper} people={people} />
    </div>
  );
};

const copyToClipboard = async (text) => {
  await navigator.clipboard.writeText(text);
};

const DisplayBibtex = ({ title, bibtex }) => {
  const [show, setShow] = useState(false);
  return (
    <div>
      <h3>{title}</h3>
      <Button
        variant="light"
        onClick={(e) => {
          copyToClipboard(bibtex);
          setShow(true);
        }}
      >
        <ImCopy />
      </Button>
      <BibTexStyled>{bibtex}</BibTexStyled>
      <StyledToast
        onClose={() => setShow(false)}
        show={show}
        delay={3000}
        autohide
      >
        <Toast.Body>
          <MdCheckCircle color={"green"} size={25} /> Copied BibTex to
          clipboard.
        </Toast.Body>
      </StyledToast>
    </div>
  );
};

const BibTex = ({ papers, BibTexFile }) => {
  let { paperId } = useParams();
  let paper = papers.find((p) => p.urlId === paperId);

  if (paper.bibTex) {
    let bibtex = paper.bibTex.replace(/},\s?/g, "},\n");
    return <DisplayBibtex title={paper.title} bibtex={bibtex} />;
  } else {
    if (paperId in BibTexFile) {
      return <DisplayBibtex title={paper.title} bibtex={BibTexFile[paperId]} />;
    } else {
      return <></>;
    }
  }
};

const Papers = ({ papers, people }) => {
  papers.sort(function (a, b) {
    return b.year - a.year;
  });

  const ShowHeaderYear = ({ pub, prevPub }) => {
    if (prevPub === undefined || prevPub.year !== pub.year) {
      return <StyledYear>{pub.year}</StyledYear>;
    } else {
      return <></>;
    }
  };
  return (
    <>
      {papers.map((pub, index) => (
        <>
          <ShowHeaderYear pub={pub} prevPub={papers[index - 1]} />
          <Citation key={index} pub={pub} people={people} />
        </>
      ))}
    </>
  );
};

const Publications = ({ papers, people, BibTexFile }) => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path}>
        <StyledTitle id="papers"> Publications</StyledTitle>
        <Papers papers={papers.publications} people={people} />
      </Route>
      <Route path={`${path}/bibtex/:paperId`}>
        <BibTex papers={papers.publications} BibTexFile={BibTexFile} />
      </Route>
      <Route path={`${path}/:paperId`}>
        <Paper papers={papers.publications} people={people} />
      </Route>
    </Switch>
  );
};

export default Publications;
