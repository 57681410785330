import React from "react";

import { useParams } from "react-router-dom";
import { Col, Row, Image } from "react-bootstrap";
import {
  PersonName,
  PersonTitle,
  PersonDescription,
  StyledIconLink,
  PubTitle,
  StyledYear,
} from "./styles";
import Citation from "../../components/Citation";
import {
  AiFillHome,
  AiFillLinkedin,
  AiFillGithub,
  MdEmail,
  AiFillTwitterSquare,
  BsLink45Deg,
  FaOrcid,
  SiGooglescholar,
} from "react-icons/all";
import { Helmet } from "react-helmet";

const IconMaterial = ({ material }) => {
  switch (material[0]) {
    case "Homepage":
      return (
        <StyledIconLink href={material[1]}>
          <AiFillHome size={20} /> {material[0]}
        </StyledIconLink>
      );
    case "ORCID":
      return (
        <StyledIconLink href={material[1]}>
          <FaOrcid size={20} /> {material[0]}
        </StyledIconLink>
      );
    case "Google Scholar":
      return (
        <StyledIconLink href={material[1]}>
          <SiGooglescholar size={20} /> {material[0]}
        </StyledIconLink>
      );
    case "LinkedIn":
      return (
        <StyledIconLink href={material[1]}>
          <AiFillLinkedin size={20} /> {material[0]}
        </StyledIconLink>
      );
    case "GitHub":
      return (
        <StyledIconLink href={material[1]}>
          <AiFillGithub size={20} /> {material[0]}
        </StyledIconLink>
      );
    case "Lab GitHub":
      return (
        <StyledIconLink href={material[1]}>
          <AiFillGithub size={20} /> {material[0]}
        </StyledIconLink>
      );
    case "Email":
      return (
        <StyledIconLink href={"mailto:" + material[1]}>
          <MdEmail size={20} /> {material[0]}
        </StyledIconLink>
      );
    case "Twitter":
      return (
        <StyledIconLink href={material[1]}>
          <AiFillTwitterSquare size={20} /> {material[0]}
        </StyledIconLink>
      );
    default:
      return (
        <StyledIconLink href={material[1]}>
          <BsLink45Deg size={20} /> {material[0]}
        </StyledIconLink>
      );
  }
};

const Person = ({ peopleDict, papers }) => {
  let { personId } = useParams();
  let person = Object.entries(peopleDict).find(
    (nameInfo) => nameInfo[1].name.trim().replaceAll(" ", "-") === personId
  );
  if (person) {
    person = person[1];
  }

  let personPub = papers.filter((paper) => {
    if (paper.authors.includes(person.name)) {
      return true;
    } else if (person.alias) {
      for (const name of person.alias) {
        if (paper.authors.includes(name)) {
          return true;
        }
      }
      return false;
    } else {
      return false;
    }
  });
  personPub.sort(function (a, b) {
    return b.year - a.year;
  });

  const ShowHeaderYear = ({ pub, prevPub }) => {
    if (prevPub === undefined || prevPub.year !== pub.year) {
      return <StyledYear>{pub.year}</StyledYear>;
    } else {
      return <></>;
    }
  };

  let listMaterials = Object.entries(person.materials);
  listMaterials = listMaterials.filter((pair) => pair[1]);

  return (
    <>
      <Helmet>
        <title>{person.name} - Khoury Data Visualization, Northeastern University</title>
        <meta name="description" content={person.name + " - Khoury Data Visualization, Northeastern University"} />
      </Helmet>
      <Row>
        <Col xs={10} sm={5} lg={"auto"}>
          {person.image && (
            <Image
              src={process.env.PUBLIC_URL + person.image}
              width={255}
              rounded
            ></Image>
          )}
        </Col>
        <Col>
          <PersonName style={{ fontSize: "32px" }}>{person.name}</PersonName>
          <PersonTitle style={{ fontSize: "18px" }}>{person.title}</PersonTitle>
          <PersonDescription>{person.description}</PersonDescription>
          {listMaterials.map((pair, index) => (
            <Row>
              <IconMaterial key={index} material={pair} />
            </Row>
          ))}
        </Col>
      </Row>
      {personPub.length > 0 && <PubTitle>Publications</PubTitle>}
      {personPub.map((pub, index) => (
        <>
          <ShowHeaderYear pub={pub} prevPub={personPub[index - 1]} />
          <Citation key={index} pub={pub} people={peopleDict} />
        </>
      ))}
    </>
  );
};

export default Person;
