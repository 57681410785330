import React from "react";
import Media from "../Media";
const Footer = ({ user }) => {
  return (
    <>
      <hr />
      {/* <Media media ={basics} /> */}
      <div className="col-6 col-md">
        <p style={{ color: "gray" }}>
          Data Visualization @ Khoury —
          Northeastern University<br />
          West Village H, Room 302<br />
           440 Huntington Ave, 
          Boston, MA 02115, USA
        </p>
      </div>
    </>
  );
};

export default Footer;
