import React from "react";
import PersonLink from "../../components/PersonLink";

const Authors = ({ names, people }) => {
  return names.map((name, index) => (
    <>
      <PersonLink key={index} name={name} people={people} />
      {index < names.length - 2
        ? ", "
        : index < names.length - 1
        ? ", and "
        : ""}
    </>
  ));
};

export default Authors;
