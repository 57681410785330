import React from "react";
import { Row } from "react-bootstrap";
import { StyledTitle } from "../../styles";
import { UpdateDate, UpdateText } from "../Home/styles";

const Archive = ({ data }) => {
  let recUpdates = data.updates;
  return (
    <>
      <StyledTitle>Archive Updates</StyledTitle>
      {recUpdates.map((update, index) => (
        <Row key={index}>
          <div>
            <UpdateDate>{update.date}</UpdateDate>:{" "}
            <UpdateText dangerouslySetInnerHTML={{ __html: update.html }} />
          </div>
        </Row>
      ))}
    </>
  );
};

export default Archive;
