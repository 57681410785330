import React from "react";
import dataHome from "./data/home.json";
import dataPapers from "./data/papers.json";
import dataPeople from "./data/people.json";
import dataProjects from "./data/projects.json";
import dataTeaching from "./data/teaching.json";
import Pages from "./pages";

function App() {
  return (
    <Pages
      home={dataHome}
      papers={dataPapers}
      people={dataPeople}
      projects={dataProjects}
      teaching={dataTeaching}
    />
  );
}

export default App;
