import styled from "styled-components";
import { Carousel, Row } from "react-bootstrap";

export const CarouselImg = styled.img`
  padding-bottom: 65px;
`;

export const StyledCarousel = styled(Carousel)`
  margin: auto;
`;

export const CenteredRow = styled(Row)`
  padding-bottom: 10px;
`;

export const UpdateDate = styled.span`
  font-style: italic;
  font-weight: bold;
  font-family: "HelveticaNeueW01-67MdCn 692710", "HelveticaNeueW01-45Ligh",
    "Helvetica Neue", HelveticaNeue, Helvetica, sans-serif;
`;

export const UpdateText = styled.span`
  padding-bottom: 10px;
`;

export const CarouselText = styled.span`
  background-color: black;
  font-family: "HelveticaNeueW01-67MdCn 692710", "HelveticaNeueW01-45Ligh",
    "Helvetica Neue", HelveticaNeue, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 16px;
  opacity: 40%;
  border-radius: 4px;
  padding: 2px 40px;
`;
