import React from "react";
import { BrowserRouter, Switch, Route, useRouteMatch } from "react-router-dom";
import { Card, Row } from "react-bootstrap";
import {
  StyledCard,
  PersonName,
  PersonTitle,
  PersonDescription,
  StyledLink,
  PeopleCol,
} from "./styles";
import { StyledTitle } from "../../styles";
import Person from "./Person";

const PeopleCard = ({ person, section }) => {
  return (
    <PeopleCol>
      <StyledCard>
        {person.image && section !== "Alumni" && (
          <Card.Img variant="top" src={process.env.PUBLIC_URL + person.image} />
        )}
        <Card.Body>
          <PersonName>{person.name}</PersonName>
          <PersonTitle>{person.title}</PersonTitle>
          <PersonDescription>{person.description}</PersonDescription>
        </Card.Body>
      </StyledCard>
    </PeopleCol>
  );
};

const Position = ({ people }) => {
  return Object.entries(people).map((pair, index) => (
    <>
      {/* <Container key={index}> */}
      <hr />
      <StyledTitle id={pair[0]}>{pair[0]}</StyledTitle>
      <Row xs={1} md={4}>
        {pair[1].map((person, index) => (
          <StyledLink
            key={index}
            href={process.env.PUBLIC_URL + "/people/" + person.name.trim().replaceAll(" ", "-")}
          >
            <PeopleCard person={person} section={pair[0]} />
          </StyledLink>
        ))}
      </Row>
      {/* </Container> */}
    </>
  ));
};

const People = ({ papers, people, peopleDict }) => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path}>
        <Position people={people} />
      </Route>
      <Route path={`${path}/:personId`}>
        <Person peopleDict={peopleDict} papers={papers} />
      </Route>
    </Switch>
  );
};

export default People;
