import styled from "styled-components";
import { Card, Col } from "react-bootstrap";

export const PubMaterial = styled.a`
  color: #000;
  text-decoration: none;
`;

export const StyledCard = styled(Card)`
  width: "18rem";
  color: #000000;
  transition: transform 0.2s;
  font-family: "HelveticaNeueW01-65Medi", "HelveticaNeueW01-45Ligh",
    "Helvetica Neue", HelveticaNeue, Helvetica, sans-serif;
  font-weight: normal;
  text-transform: none;
  border: none;
  &:hover {
    color: #000000;
    -moz-box-shadow: 0 0 10px #000000;
    -webkit-box-shadow: 0 0 10px #000000;
    box-shadow: 0 0 10px #000000;
    text-decoration: none;
    transform: translateY(0) scale(1.03);
  }
`;

export const PeopleCol = styled(Col)`
  padding-bottom: 15px;
  padding-top: 15px;
`;

export const PersonName = styled(Card.Title)`
  color: #e11a2c;
  font-size: 21px;
  &:hover {
    text-decoration: underline;
  }
`;

export const PersonTitle = styled(Card.Subtitle)`
  margin-top: 0;
  color: #615445;
  font-size: 14px;
  margin-bottom: 10px;
  white-space: pre-wrap;
`;

export const PersonDescription = styled(Card.Subtitle)`
  margin-top: 0;
  color: #615445;
  text-transform: none;
  font-weight: normal;
  margin-bottom: 10px;
`;

export const StyledLink = styled.a`
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`;

export const StyledIconLink = styled.a`
  color: black;
  width: auto;
  opacity: 70%;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`;

export const PubTitle = styled.h3`
  font-family: "HelveticaNeueW01-67MdCn 692710", "HelveticaNeueW01-45Ligh",
    "Helvetica Neue", HelveticaNeue, Helvetica, sans-serif;
  font-weight: normal;
  line-height: 1.1;
  color: #615445;
  font-size: 30px;
  padding-top: 10px;
  text-transform: uppercase;
  margin-bottom: 10px;
`;

export const StyledYear = styled.h5`
  color: #e11a2c;
  font-size: 25px;
  padding-left: 4px;
`;
